import React from "react";
import ThePage from './components/ThePage';


function App() {
    return (
        <ThePage/>
    );
}

export default App;