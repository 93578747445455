import React from "react";
import TheTitle from "./TheTitle.js";
import TheFacts from "./TheFacts.js";

function TheMainContent() {
    return (
        <div className="main">
            <TheTitle/>
            <TheFacts/>
        </div>
    )
}

export default TheMainContent;