import React from "react";
import logo from "../resources/images/reactjs-icon.png";

function TheHeader() {
    return (
        <header className="header">
            <div className="logo">
                <img src={logo} alt="logo"/>
                <div>ReactFacts</div>
            </div>
            <div className="info">
                React Course - Project 1
            </div>
        </header>
    )
}

export default TheHeader;