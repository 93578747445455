import React from "react";
import TheHeader from "./TheHeader";
import TheMainContent from "./TheMainContent";

function ThePage() {
    return (
        <div className="page">
            <TheHeader/>
            <TheMainContent/>
        </div>
    )
}

export default ThePage;